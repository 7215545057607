var render = function () {
  var _vm$boardInfo, _vm$boardInfo$departm, _vm$boardInfo$departm2, _vm$boardInfo2, _vm$boardInfo2$milest, _vm$boardInfo2$milest2, _vm$boardInfo3, _vm$milestoneBoardInf;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('div', [_c('b-row', [_c('b-col', [_vm.boardInfo ? [_c('b-breadcrumb', {
    staticClass: "breadcrumb-chevron pl-0"
  }, [_c('b-breadcrumb-item', {
    on: {
      "click": _vm.onShowDepartmentDetails
    }
  }, [_vm._v(" Dept: " + _vm._s((_vm$boardInfo = _vm.boardInfo) === null || _vm$boardInfo === void 0 ? void 0 : (_vm$boardInfo$departm = _vm$boardInfo.department) === null || _vm$boardInfo$departm === void 0 ? void 0 : (_vm$boardInfo$departm2 = _vm$boardInfo$departm.data) === null || _vm$boardInfo$departm2 === void 0 ? void 0 : _vm$boardInfo$departm2.name) + " ")]), _c('b-breadcrumb-item', {
    on: {
      "click": _vm.onShowMilestones
    }
  }, [_vm._v(" Milestone: " + _vm._s((_vm$boardInfo2 = _vm.boardInfo) === null || _vm$boardInfo2 === void 0 ? void 0 : (_vm$boardInfo2$milest = _vm$boardInfo2.milestone) === null || _vm$boardInfo2$milest === void 0 ? void 0 : (_vm$boardInfo2$milest2 = _vm$boardInfo2$milest.data) === null || _vm$boardInfo2$milest2 === void 0 ? void 0 : _vm$boardInfo2$milest2.title))]), _c('b-breadcrumb-item', {
    on: {
      "click": _vm.onShowPage
    }
  }, [_vm._v(" " + _vm._s((_vm$boardInfo3 = _vm.boardInfo) === null || _vm$boardInfo3 === void 0 ? void 0 : _vm$boardInfo3.title))])], 1)] : _vm._e()], 2), _c('b-col', [_c('div', {
    staticClass: "custom-search d-flex align-items-center justify-content-end"
  }, [_c('div', {
    staticClass: "d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
  }, [_vm.$permissionAbility(_vm.MILESTONE_BOARD_TASK_CREATE, _vm.permissions) ? [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0 mr-1 btn-sm",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.showModal
    }
  }, [_vm._v(" Create Task ")]), _c('b-sidebar', {
    attrs: {
      "id": "sidebar-right",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadows": ""
    }
  }, [_c('div', [_c('validation-observer', {
    ref: "taskEdit"
  }, [_c('b-form', {
    staticClass: "mr-1",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.taskEditForm($event);
      }
    }
  }, [_c('h4', [_vm._v("Edit Task Information")]), _c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', {
    attrs: {
      "md": "10",
      "lg": "10",
      "xs": "12"
    }
  }, [_c('div', {
    attrs: {
      "id": "component-breadcrumbs"
    }
  }, [_c('b-breadcrumb', {
    staticClass: "breadcrumb-chevron"
  }, [_c('b-breadcrumb-item', {
    attrs: {
      "active": ""
    }
  }, [_vm._v(" " + _vm._s((_vm$milestoneBoardInf = _vm.milestoneBoardInfo) === null || _vm$milestoneBoardInf === void 0 ? void 0 : _vm$milestoneBoardInf.title) + " ")]), _c('b-breadcrumb-item', {
    on: {
      "click": function click($event) {
        return _vm.onShowTask(_vm.breadCrumShortTitle);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.breadCrumTitle) + " -(" + _vm._s(_vm.breadCrumShortTitle) + ") ")])], 1)], 1)])], 1), [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "8",
      "lg": "8",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Title *",
      "label-for": "title"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "title",
      "vid": "title",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          staticClass: "custom-font",
          attrs: {
            "id": "title",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Enter task title"
          },
          model: {
            value: _vm.title,
            callback: function callback($$v) {
              _vm.title = $$v;
            },
            expression: "title"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 4147875367)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Type ",
      "label-for": "type"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "type",
      "vid": "type"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-form-input', {
          staticClass: "custom-font",
          attrs: {
            "id": "type",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Enter task type"
          },
          model: {
            value: _vm.type,
            callback: function callback($$v) {
              _vm.type = $$v;
            },
            expression: "type"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1004053375)
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Priority ",
      "label-for": "priority"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "priority",
      "vid": "priority"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('v-select', {
          staticClass: "custom-font",
          attrs: {
            "id": "priority",
            "options": _vm.priorityOptionConstants,
            "reduce": function reduce(option) {
              return option.value;
            },
            "placeholder": "Select task priority",
            "label": "name"
          },
          model: {
            value: _vm.selectPriorityType,
            callback: function callback($$v) {
              _vm.selectPriorityType = $$v;
            },
            expression: "selectPriorityType"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2966992974)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Estimation Point",
      "label-for": "point"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "point",
      "vid": "point",
      "rules": "min_value:0|max_value:9999"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('b-form-input', {
          staticClass: "custom-font",
          attrs: {
            "id": "point",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Enter Estimation Point"
          },
          model: {
            value: _vm.point,
            callback: function callback($$v) {
              _vm.point = $$v;
            },
            expression: "point"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 923793085)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Label",
      "label-for": "labels"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "labels",
      "vid": "labels"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('v-select', {
          staticClass: "custom-font",
          attrs: {
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "id": "labels",
            "placeholder": "Add Task Label",
            "label": "name",
            "multiple": "",
            "taggable": "",
            "push-tags": ""
          },
          model: {
            value: _vm.selectLabels,
            callback: function callback($$v) {
              _vm.selectLabels = $$v;
            },
            expression: "selectLabels"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 4293640894)
  })], 1)], 1)], 1), _vm.previousFiles.length > 0 ? [_c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', [_c('div', {
    staticClass: "scrollable-file-container"
  }, [_c('div', {
    staticClass: "uploaded-files mb-2 scrollable-file-content"
  }, _vm._l(_vm.previousFiles, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "file-preview"
    }, [_c('b-row', {
      staticClass: "mr-1"
    }, [_c('b-col', {
      attrs: {
        "md": "8",
        "lg": "8",
        "xs": "12"
      }
    }, [_c('div', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover.bottom",
        value: item.file_name,
        expression: "\n                                            item.file_name\n                                          ",
        modifiers: {
          "hover": true,
          "bottom": true
        }
      }]
    }, [_vm.isPreviousFileImage(item) ? [_c('img', {
      attrs: {
        "src": item === null || item === void 0 ? void 0 : item.original_url,
        "alt": "Preview",
        "width": "100",
        "height": "100"
      }
    })] : [_c('svg', {
      attrs: {
        "width": "100px",
        "height": "100px",
        "viewBox": "0 0 24 24",
        "xmlns": "http://www.w3.org/2000/svg",
        "fill": "#000000"
      }
    }, [_c('g', {
      attrs: {
        "id": "SVGRepo_bgCarrier",
        "stroke-width": "0"
      }
    }), _c('g', {
      attrs: {
        "id": "SVGRepo_tracerCarrier",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }), _c('g', {
      attrs: {
        "id": "SVGRepo_iconCarrier"
      }
    }, [_c('title'), _c('g', {
      attrs: {
        "id": "Complete"
      }
    }, [_c('g', {
      attrs: {
        "id": "F-File"
      }
    }, [_c('g', {
      attrs: {
        "id": "Text"
      }
    }, [_c('g', [_c('path', {
      attrs: {
        "d": "M18,22H6a2,2,0,0,1-2-2V4A2,2,0,0,1,6,2h7.1a2,2,0,0,1,1.5.6l4.9,5.2A2,2,0,0,1,20,9.2V20A2,2,0,0,1,18,22Z",
        "fill": "none",
        "id": "File",
        "stroke": "#000000",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2"
      }
    }), _c('line', {
      attrs: {
        "fill": "none",
        "stroke": "#000000",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2",
        "x1": "7.9",
        "x2": "16.1",
        "y1": "17.5",
        "y2": "17.5"
      }
    }), _c('line', {
      attrs: {
        "fill": "none",
        "stroke": "#000000",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2",
        "x1": "7.9",
        "x2": "16.1",
        "y1": "13.5",
        "y2": "13.5"
      }
    }), _c('line', {
      attrs: {
        "fill": "none",
        "stroke": "#000000",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2",
        "x1": "8",
        "x2": "13",
        "y1": "9.5",
        "y2": "9.5"
      }
    })])])])])])])]], 2)]), _c('b-col', {
      attrs: {
        "md": "3",
        "lg": "3",
        "xs": "12"
      }
    }, [_c('b-button', {
      directives: [{
        name: "ripple",
        rawName: "v-ripple.400",
        value: 'rgba(255, 255, 255, 0.15)',
        expression: "\n                                            'rgba(255, 255, 255, 0.15)'\n                                          ",
        modifiers: {
          "400": true
        }
      }],
      staticClass: "flex-shrink-0 btn-sm",
      attrs: {
        "variant": "outline-danger"
      },
      on: {
        "click": function click($event) {
          return _vm.removePreviousFile(index);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "XIcon"
      }
    })], 1)], 1)], 1)], 1);
  }), 0)])])], 1)] : _vm._e(), _c('b-row', [_c('b-col', [_c('div', {
    staticClass: "file-input"
  }, [_c('label', {
    staticClass: "attach-icon",
    attrs: {
      "for": "file-upload"
    }
  }, [_vm._v(" Attach File "), _c('feather-icon', {
    staticClass: "mr-50",
    staticStyle: {
      "color": "#7367f0"
    },
    attrs: {
      "icon": "PaperclipIcon"
    }
  })], 1), _c('input', {
    attrs: {
      "hidden": "",
      "id": "file-upload",
      "type": "file",
      "multiple": ""
    },
    on: {
      "change": _vm.handleFileUpload
    }
  })])])], 1), _c('b-row', [_c('b-col', [_c('div', {
    staticClass: "scrollable-file-container"
  }, [_c('div', {
    staticClass: "uploaded-files mb-2 scrollable-file-content"
  }, _vm._l(_vm.uploadedFiles, function (file, index) {
    return _c('div', {
      key: index,
      staticClass: "file-preview"
    }, [_c('b-row', {
      staticClass: "mr-1"
    }, [_c('b-col', {
      attrs: {
        "md": "8",
        "lg": "8",
        "xs": "12"
      }
    }, [_c('div', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover.bottom",
        value: file.name,
        expression: "file.name",
        modifiers: {
          "hover": true,
          "bottom": true
        }
      }]
    }, [_vm.isImage(file) ? [_c('img', {
      attrs: {
        "src": file.preview,
        "alt": "Preview",
        "width": "100",
        "height": "100"
      }
    })] : [_c('svg', {
      attrs: {
        "width": "100px",
        "height": "100px",
        "viewBox": "0 0 24 24",
        "xmlns": "http://www.w3.org/2000/svg",
        "fill": "#000000"
      }
    }, [_c('g', {
      attrs: {
        "id": "SVGRepo_bgCarrier",
        "stroke-width": "0"
      }
    }), _c('g', {
      attrs: {
        "id": "SVGRepo_tracerCarrier",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }), _c('g', {
      attrs: {
        "id": "SVGRepo_iconCarrier"
      }
    }, [_c('title'), _c('g', {
      attrs: {
        "id": "Complete"
      }
    }, [_c('g', {
      attrs: {
        "id": "F-File"
      }
    }, [_c('g', {
      attrs: {
        "id": "Text"
      }
    }, [_c('g', [_c('path', {
      attrs: {
        "d": "M18,22H6a2,2,0,0,1-2-2V4A2,2,0,0,1,6,2h7.1a2,2,0,0,1,1.5.6l4.9,5.2A2,2,0,0,1,20,9.2V20A2,2,0,0,1,18,22Z",
        "fill": "none",
        "id": "File",
        "stroke": "#000000",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2"
      }
    }), _c('line', {
      attrs: {
        "fill": "none",
        "stroke": "#000000",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2",
        "x1": "7.9",
        "x2": "16.1",
        "y1": "17.5",
        "y2": "17.5"
      }
    }), _c('line', {
      attrs: {
        "fill": "none",
        "stroke": "#000000",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2",
        "x1": "7.9",
        "x2": "16.1",
        "y1": "13.5",
        "y2": "13.5"
      }
    }), _c('line', {
      attrs: {
        "fill": "none",
        "stroke": "#000000",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2",
        "x1": "8",
        "x2": "13",
        "y1": "9.5",
        "y2": "9.5"
      }
    })])])])])])])]], 2)]), _c('b-col', {
      attrs: {
        "md": "3",
        "lg": "3",
        "xs": "12"
      }
    }, [_c('b-button', {
      directives: [{
        name: "ripple",
        rawName: "v-ripple.400",
        value: 'rgba(255, 255, 255, 0.15)',
        expression: "\n                                          'rgba(255, 255, 255, 0.15)'\n                                        ",
        modifiers: {
          "400": true
        }
      }],
      staticClass: "flex-shrink-0 btn-sm",
      attrs: {
        "variant": "outline-danger"
      },
      on: {
        "click": function click($event) {
          return _vm.removeFile(index);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "XIcon"
      }
    })], 1)], 1)], 1)], 1);
  }), 0)])])], 1), _c('b-row', [_c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Description",
      "label-for": "description"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "description",
      "vid": "description"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('quill-editor', {
          style: {
            height: '13rem !important',
            paddingBottom: '5rem !important'
          },
          attrs: {
            "options": _vm.editorOption
          },
          model: {
            value: _vm.description,
            callback: function callback($$v) {
              _vm.description = $$v;
            },
            expression: "description"
          }
        }, [_c('div', {
          attrs: {
            "slot": "toolbar",
            "id": "toolbar"
          },
          slot: "toolbar"
        }, [_c('span', {
          staticClass: "ql-formats"
        }, [_c('button', {
          staticClass: "ql-bold"
        }, [_vm._v("Bold")]), _c('button', {
          staticClass: "ql-italic"
        }, [_vm._v(" Italic ")]), _c('button', {
          staticClass: "ql-underline"
        }, [_vm._v(" Underline ")]), _c('button', {
          staticClass: "ql-strike"
        }, [_vm._v(" Strike ")]), _c('button', {
          staticClass: "ql-blockquote"
        }), _c('button', {
          staticClass: "ql-list",
          attrs: {
            "value": "ordered"
          }
        }), _c('button', {
          staticClass: "ql-list",
          attrs: {
            "value": "bullet"
          }
        }), _c('button', {
          staticClass: "ql-script",
          attrs: {
            "value": "sub"
          }
        }), _c('button', {
          staticClass: "ql-script",
          attrs: {
            "value": "super"
          }
        }), _c('button', {
          staticClass: "ql-indent",
          attrs: {
            "value": "-1"
          }
        }), _c('button', {
          staticClass: "ql-indent",
          attrs: {
            "value": "+1"
          }
        }), _c('button', {
          staticClass: "ql-direction",
          attrs: {
            "value": "rtl"
          }
        }), _c('button', {
          staticClass: "ql-align",
          attrs: {
            "value": ""
          }
        }), _c('button', {
          staticClass: "ql-align",
          attrs: {
            "value": "center"
          }
        }), _c('button', {
          staticClass: "ql-align",
          attrs: {
            "value": "right"
          }
        }), _c('button', {
          staticClass: "ql-align",
          attrs: {
            "value": "justify"
          }
        }), _c('select', {
          staticClass: "ql-color"
        }), _c('select', {
          staticClass: "ql-background"
        }), _c('select', {
          staticClass: "ql-size"
        }, [_c('option', {
          attrs: {
            "value": "small"
          }
        }), _c('option', {
          attrs: {
            "selected": ""
          }
        }), _c('option', {
          attrs: {
            "value": "large"
          }
        }), _c('option', {
          attrs: {
            "value": "huge"
          }
        })]), _c('select', {
          staticClass: "ql-font"
        }), _c('select', {
          staticClass: "ql-header"
        }, [_c('option', {
          attrs: {
            "value": "1"
          }
        }, [_vm._v("Heading 1")]), _c('option', {
          attrs: {
            "value": "2"
          }
        }, [_vm._v("Heading 2")]), _c('option', {
          attrs: {
            "value": "3"
          }
        }, [_vm._v("Heading 3")]), _c('option', {
          attrs: {
            "value": "4"
          }
        }, [_vm._v("Heading 4")]), _c('option', {
          attrs: {
            "value": "5"
          }
        }, [_vm._v("Heading 5")]), _c('option', {
          attrs: {
            "value": "6"
          }
        }, [_vm._v("Heading 6")]), _c('option', {
          attrs: {
            "selected": ""
          }
        }, [_vm._v("Normal")])]), _c('button', {
          staticClass: "ql-link"
        })])])]), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 219584460)
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "3",
      "lg": "3",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Assinged Start Date",
      "label-for": "start_date"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "start_date",
      "vid": "start_date"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('b-form-datepicker', {
          staticClass: "form-control custom-font",
          attrs: {
            "id": "start_date",
            "state": errors.length > 0 ? false : null,
            "locale": "en-US",
            "today-button": "",
            "close-button": "",
            "reset-button": "",
            "placeholder": "Select Start Date "
          },
          model: {
            value: _vm.startDate,
            callback: function callback($$v) {
              _vm.startDate = $$v;
            },
            expression: "startDate"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 830469533)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "lg": "3",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Actual Start Date",
      "label-for": "actual_start_date"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "actual_start_date",
      "vid": "actual_start_date"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var errors = _ref8.errors;
        return [_c('b-form-datepicker', {
          staticClass: "form-control custom-font",
          attrs: {
            "id": "actual_start_date",
            "state": errors.length > 0 ? false : null,
            "locale": "en-US",
            "today-button": "",
            "close-button": "",
            "reset-button": "",
            "placeholder": "Actual Start Date "
          },
          model: {
            value: _vm.actualStartDate,
            callback: function callback($$v) {
              _vm.actualStartDate = $$v;
            },
            expression: "actualStartDate"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 161280068)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "lg": "3",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Assigned End Date",
      "label-for": "assigned_end_date"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "assigned_end_date",
      "vid": "assigned_end_date"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var errors = _ref9.errors;
        return [_c('b-form-datepicker', {
          staticClass: "form-control custom-font",
          attrs: {
            "id": "assigned_end_date",
            "state": errors.length > 0 ? false : null,
            "locale": "en-US",
            "today-button": "",
            "close-button": "",
            "reset-button": "",
            "placeholder": "Assigned End Date "
          },
          model: {
            value: _vm.assignedEndDate,
            callback: function callback($$v) {
              _vm.assignedEndDate = $$v;
            },
            expression: "assignedEndDate"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1580021413)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "lg": "3",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Actual End Date",
      "label-for": "actual_end_date"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "actual_end_date",
      "vid": "actual_end_date"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var errors = _ref10.errors;
        return [_c('b-form-datepicker', {
          staticClass: "form-control custom-font",
          attrs: {
            "id": "actual_end_date",
            "state": errors.length > 0 ? false : null,
            "locale": "en-US",
            "today-button": "",
            "close-button": "",
            "reset-button": "",
            "placeholder": "Actual End Date "
          },
          model: {
            value: _vm.actualEndDate,
            callback: function callback($$v) {
              _vm.actualEndDate = $$v;
            },
            expression: "actualEndDate"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3339335275)
  })], 1)], 1)], 1), _c('b-row', {
    staticClass: "mt-1 mb-1"
  }, [_vm.$permissionAbility(_vm.MILESTONE_BOARD_SUB_TASK_CREATE, _vm.permissions) ? [_c('b-col', [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "btn btn-sm",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.showSubTaskForm
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "BriefcaseIcon"
    }
  }), _vm._v(" Add Sub Task ")], 1)], 1)] : _vm._e()], 2), _vm.isSubTaskFormLoaded ? [_c('b-row', {
    staticClass: "mt-1 mb-1"
  }, [_c('b-col', {
    attrs: {
      "md": "12",
      "lg": "12",
      "xs": "12"
    }
  }, [_c('validation-observer', {
    ref: "subTaskAdd"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.subTaskAddForm($event);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "8",
      "lg": "8",
      "xs": "12"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "sub_task_title",
      "vid": "title"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref11) {
        var errors = _ref11.errors;
        return [_c('b-form-input', {
          staticClass: "custom-font",
          attrs: {
            "id": "title",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Enter Sub Task Title"
          },
          model: {
            value: _vm.subTaskTitle,
            callback: function callback($$v) {
              _vm.subTaskTitle = $$v;
            },
            expression: "subTaskTitle"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1186396778)
  })], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_vm.isSubTaskLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "\n                                            'rgba(255, 255, 255, 0.15)'\n                                          ",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right btn-sm",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.closeSubTaskForm
    }
  }, [_vm._v(" Cancel ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "\n                                            'rgba(255, 255, 255, 0.15)'\n                                          ",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right mr-1 btn-sm",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Add ")])]], 2)], 1)], 1)], 1)], 1)], 1)] : _vm._e(), _c('b-row', {
    staticClass: "mt-1"
  }, [_c('b-col', {
    attrs: {
      "md": "12",
      "lg": "12",
      "xs": "12"
    }
  }, [_c('b-list-group', _vm._l(_vm.subTaskList, function (subTask) {
    var _subTask$phase, _subTask$phase$data;
    return _c('b-list-group-item', {
      key: subTask === null || subTask === void 0 ? void 0 : subTask.id,
      staticClass: "d-flex justify-content-between align-items-center"
    }, [_c('b-link', {
      on: {
        "click": function click($event) {
          return _vm.onShowSubTask(subTask === null || subTask === void 0 ? void 0 : subTask.id);
        }
      }
    }, [_vm._v(_vm._s(subTask === null || subTask === void 0 ? void 0 : subTask.title))]), _c('div', {
      staticClass: "custom-search d-flex align-items-center justify-content-end"
    }, [_c('b-badge', {
      staticClass: "mr-1",
      attrs: {
        "variant": "light-primary"
      }
    }, [_vm._v(_vm._s(subTask === null || subTask === void 0 ? void 0 : (_subTask$phase = subTask.phase) === null || _subTask$phase === void 0 ? void 0 : (_subTask$phase$data = _subTask$phase.data) === null || _subTask$phase$data === void 0 ? void 0 : _subTask$phase$data.title))]), _c('b-button', {
      directives: [{
        name: "ripple",
        rawName: "v-ripple.400",
        value: 'rgba(255, 255, 255, 0.15)',
        expression: "'rgba(255, 255, 255, 0.15)'",
        modifiers: {
          "400": true
        }
      }],
      staticClass: "mr-1 btn-sm",
      attrs: {
        "variant": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.onShowSubTask(subTask === null || subTask === void 0 ? void 0 : subTask.id);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "EditIcon"
      }
    })], 1), _c('b-button', {
      directives: [{
        name: "ripple",
        rawName: "v-ripple.400",
        value: 'rgba(255, 255, 255, 0.15)',
        expression: "'rgba(255, 255, 255, 0.15)'",
        modifiers: {
          "400": true
        }
      }],
      staticClass: "btn-sm",
      attrs: {
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.onDeleteSubTask(subTask === null || subTask === void 0 ? void 0 : subTask.id);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "TrashIcon"
      }
    })], 1)], 1)], 1);
  }), 1)], 1)], 1)], _vm.$permissionAbility(_vm.MILESTONE_BOARD_TASK_EDIT, _vm.permissions) ? [_vm.isLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right mt-2 mb-2",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")])]] : _vm._e()], 2)], 1)], 1)])] : _vm._e(), _vm.$permissionAbility(_vm.MILESTONE_BOARD_TASK_CREATE, _vm.permissions) ? [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0 btn-sm",
    attrs: {
      "variant": "danger"
    },
    on: {
      "click": _vm.goToTrash
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "Trash2Icon"
    }
  })], 1)] : _vm._e()], 2)])])], 1), _vm.isBacklogTaskListLoading ? _c('div', {
    staticClass: "text-center"
  }, [_c('b-spinner', {
    staticClass: "mr-1",
    staticStyle: {
      "width": "3rem",
      "height": "3rem"
    },
    attrs: {
      "variant": "primary"
    }
  })], 1) : _c('div', [_vm.backlogTaskList.length > 0 ? [_c('b-list-group', _vm._l(_vm.backlogTaskList, function (item, index) {
    var _item$team, _item$phase, _item$phase$data;
    return _c('div', {
      key: index
    }, [_c('b-list-group-item', {
      staticClass: "d-flex justify-content-between align-items-center"
    }, [_c('b-link', {
      directives: [{
        name: "b-toggle",
        rawName: "v-b-toggle.sidebar-right",
        modifiers: {
          "sidebar-right": true
        }
      }],
      staticStyle: {
        "color": "black"
      },
      on: {
        "click": function click($event) {
          return _vm.onShowTaskSidebar(item);
        }
      }
    }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.title) + " ")]), _c('div', {
      staticClass: "custom-search d-flex align-items-center justify-content-end"
    }, [_c('div', {
      staticClass: "mr-1"
    }, [_c('div', [_vm._l(item === null || item === void 0 ? void 0 : (_item$team = item.team) === null || _item$team === void 0 ? void 0 : _item$team.data, function (member, index) {
      return _c('span', {
        key: index
      }, [index <= 2 ? [_c('b-avatar', {
        staticStyle: {
          "margin": "1px"
        },
        attrs: {
          "src": (member === null || member === void 0 ? void 0 : member.avatar) === '' ? '/avatar.svg' : member === null || member === void 0 ? void 0 : member.avatar,
          "size": "26"
        }
      })] : _vm._e()], 2);
    }), (item === null || item === void 0 ? void 0 : item.team_count) > 3 ? _c('span', [_vm._v(" + " + _vm._s((item === null || item === void 0 ? void 0 : item.team_count) - 3) + " more ")]) : _vm._e(), _c('b-button', {
      staticClass: "btn-icon rounded-circle",
      staticStyle: {
        "margin-left": "5px"
      },
      attrs: {
        "variant": "outline-primary",
        "disabled": _vm.$permissionAbility(_vm.MILESTONE_BOARD_TASK_DELETE, _vm.permissions) ? false : true
      },
      on: {
        "click": function click($event) {
          return _vm.showAddMemberModal(item);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "UserPlusIcon"
      }
    })], 1)], 2)]), _c('div', {
      staticClass: "mr-1"
    }, [_c('b-dropdown', {
      attrs: {
        "no-caret": "",
        "variant": "outline-primary",
        "text": item === null || item === void 0 ? void 0 : (_item$phase = item.phase) === null || _item$phase === void 0 ? void 0 : (_item$phase$data = _item$phase.data) === null || _item$phase$data === void 0 ? void 0 : _item$phase$data.title,
        "size": "sm"
      }
    }, [_vm._l(_vm.phaseIdOptions, function (phase) {
      return _c('b-dropdown-item', {
        key: phase === null || phase === void 0 ? void 0 : phase.id,
        on: {
          "click": function click($event) {
            return _vm.updatePhase(item, phase === null || phase === void 0 ? void 0 : phase.id);
          }
        }
      }, [_vm._v(" " + _vm._s(phase === null || phase === void 0 ? void 0 : phase.title) + " ")]);
    }), _c('b-dropdown-divider'), _c('b-dropdown-item', {
      on: {
        "click": _vm.onShowPhase
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "PlusCircleIcon"
      }
    }), _vm._v(" Add More ")], 1)], 2)], 1), _vm.$permissionAbility(_vm.MILESTONE_BOARD_TASK_DELETE, _vm.permissions) || _vm.$permissionAbility(_vm.MILESTONE_BOARD_TASK_EDIT, _vm.permissions) ? [_c('div', [_c('b-dropdown', {
      directives: [{
        name: "ripple",
        rawName: "v-ripple.400",
        value: 'rgba(113, 102, 240, 0.15)',
        expression: "'rgba(113, 102, 240, 0.15)'",
        modifiers: {
          "400": true
        }
      }],
      attrs: {
        "variant": "link",
        "no-caret": ""
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function fn() {
          return [_c('feather-icon', {
            staticClass: "text-body align-middle mr-25",
            attrs: {
              "icon": "MoreVerticalIcon",
              "size": "16"
            }
          })];
        },
        proxy: true
      }], null, true)
    }, [_vm.$permissionAbility(_vm.MILESTONE_BOARD_TASK_EDIT, _vm.permissions) ? [_c('b-dropdown-item', {
      directives: [{
        name: "b-toggle",
        rawName: "v-b-toggle.sidebar-right",
        modifiers: {
          "sidebar-right": true
        }
      }],
      on: {
        "click": function click($event) {
          return _vm.onShowTaskSidebar(item);
        }
      }
    }, [_c('feather-icon', {
      staticClass: "mr-50",
      attrs: {
        "icon": "Edit2Icon"
      }
    }), _c('span', [_vm._v("Edit")])], 1)] : _vm._e(), _vm.$permissionAbility(_vm.MILESTONE_BOARD_TASK_DELETE, _vm.permissions) ? [_c('b-dropdown-item', {
      on: {
        "click": function click($event) {
          return _vm.onDelete(item === null || item === void 0 ? void 0 : item.id);
        }
      }
    }, [_c('feather-icon', {
      staticClass: "mr-50",
      attrs: {
        "icon": "TrashIcon"
      }
    }), _c('span', [_vm._v("Delete")])], 1)] : _vm._e()], 2)], 1)] : _vm._e()], 2)], 1)], 1);
  }), 0)] : [_c('b-list-group', [_c('b-list-group-item', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('h5', [_vm._v("No Task Found In Back Log")])])], 1)]], 2)], 1), _c('b-modal', {
    attrs: {
      "id": "modal-task-board-phase-task",
      "centered": "",
      "title": "Create Task",
      "hide-footer": "",
      "size": "lg",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenModal
    }
  }, [_c('validation-observer', {
    ref: "backlogTask"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.backlogTaskForm($event);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "lg": "12",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Title *",
      "label-for": "title"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "title",
      "vid": "title",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref12) {
        var errors = _ref12.errors;
        return [_c('b-form-input', {
          staticClass: "custom-font",
          attrs: {
            "id": "title",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Enter task title"
          },
          model: {
            value: _vm.title,
            callback: function callback($$v) {
              _vm.title = $$v;
            },
            expression: "title"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _vm.isLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right mt-2",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "modal-add-member-form",
      "centered": "",
      "title": "Add Member",
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenAddMemberModal
    }
  }, [_c('validation-observer', {
    ref: "addMemberValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.addMemberValidationForm($event);
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Member",
      "label-for": "user_id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "user_id",
      "vid": "user_id"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref13) {
        var errors = _ref13.errors;
        return [_c('v-select', {
          attrs: {
            "id": "user_id",
            "placeholder": "Search by employee name or email",
            "filterable": false,
            "options": _vm.memberIdOptions,
            "reduce": function reduce(country) {
              return country.id;
            },
            "label": "name",
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "multiple": ""
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(data) {
              return [_c('UserSelect', {
                attrs: {
                  "user": data
                }
              })];
            }
          }], null, true),
          model: {
            value: _vm.selectMemberIds,
            callback: function callback($$v) {
              _vm.selectMemberIds = $$v;
            },
            expression: "selectMemberIds"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _vm.isLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Add ")])]], 2)], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "modal-task-board-phase",
      "centered": "",
      "title": "Change Phase",
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenPhaseModal
    }
  }, [_c('validation-observer', {
    ref: "changePhaseValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.changePhaseValidationForm($event);
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Phase",
      "label-for": "phase_id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "phase_id",
      "vid": "phase_id"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref14) {
        var errors = _ref14.errors;
        return [_c('v-select', {
          attrs: {
            "id": "phase_id",
            "placeholder": "Search by phase name",
            "options": _vm.phaseIdOptions,
            "reduce": function reduce(item) {
              return item.id;
            },
            "label": "name"
          },
          model: {
            value: _vm.selectPhaseId,
            callback: function callback($$v) {
              _vm.selectPhaseId = $$v;
            },
            expression: "selectPhaseId"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _vm.isLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Save ")])]], 2)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }